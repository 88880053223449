import React, { useCallback, useEffect, useState } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  Controls,
  Background,
} from "@xyflow/react";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "@xyflow/react/dist/style.css";

import CustomHorizontalNode from "./CustomHorizontalNode";
import CustomVerticalNode from "./CustomVerticalNode";
import getLayoutedElements from "./utils/graphBuilder";

const nodeTypes = {
  customHorizontalNode: CustomHorizontalNode,
  customVerticalNode: CustomVerticalNode,
};

const LayoutFlow = (props) => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);

  const BACKEND_URI = window.location.hostname;

  const onLayout = useCallback(
    (direction) => {
      const updatedNodes = nodes.map((node) => ({
        ...node,
        type:
          direction === "TB" ? "customHorizontalNode" : "customVerticalNode",
      }));

      const layouted = getLayoutedElements(updatedNodes, edges, { direction });
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges, fitView]
  );

  // Add node with title and description
  const addNode = () => {
    const title = window.prompt("Enter node title:");
    const description = window.prompt("Enter node description:");

    if (title && description) {
      const newNode = {
        id: uuidv4(),
        data: { title, description },
        position: { x: Math.random() * 500, y: Math.random() * 300 },
        type: "customHorizontalNode",
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);
      setCounter(counter + 1);
    }
  };

  const deleteNode = () => {
    if (selectedNode) {
      setNodes((prevNodes) =>
        prevNodes.filter((node) => node.id !== selectedNode.id)
      );
      setEdges((prevEdges) =>
        prevEdges.filter(
          (edge) =>
            edge.source !== selectedNode.id && edge.target !== selectedNode.id
        )
      );
      setSelectedNode(null);
      setCounter(counter + 1);
    }
  };

  const onConnect = useCallback(
    (params) => {
      const animatedEdge = { ...params, animated: true };
      setEdges((eds) => addEdge(animatedEdge, eds));
      setCounter(counter + 1);
    },
    [setEdges]
  );

  const deleteEdge = () => {
    if (selectedEdge) {
      setEdges((prevEdges) =>
        prevEdges.filter((edge) => edge.id !== selectedEdge.id)
      );
      setCounter(counter + 1);
      setSelectedEdge(null);
    }
  };

  const onNodeClick = (event, node) => {
    setSelectedNode(node);
    setSelectedEdge(null);
  };

  const onEdgeClick = (event, edge) => {
    setSelectedEdge(edge);
    setSelectedNode(null);
  };

  useEffect(() => {
    axios
      .get(`/api/graph`)
      .then((response) => {
        const { nodes, edges } = response.data;
        setNodes(
          nodes.map((node) => ({
            ...node,
            type: "customHorizontalNode",
          }))
        );
        setEdges(edges);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching graph data:", error));
  }, []);

  useEffect(() => {
    console.log(counter);
    if (!isLoading) {
      axios
        .post(`/api/graph/update`, {
          nodes,
          edges,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error("Error saving graph:", error);
        });
    }
  }, [counter]);

  return isLoading ? (
    <div role="status" className="flex items-center justify-center h-screen">
      <svg
        aria-hidden="true"
        class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  ) : (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        nodeTypes={nodeTypes}
        fitView
        className="bg-slate-950"
      >
        <Panel position="top-right">
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => onLayout("TB")}
          >
            Vertical layout
          </button>
          <button
            className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
            onClick={() => onLayout("LR")}
          >
            Horizontal layout
          </button>
          <button
            className="text-white mx-1.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            onClick={addNode}
          >
            Add Node
          </button>
          <button
            className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            onClick={deleteNode}
            disabled={!selectedNode}
          >
            Delete Node
          </button>
          <button
            className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            onClick={deleteEdge}
            disabled={!selectedEdge}
          >
            Delete Edge
          </button>
        </Panel>
        <Panel position="top-left">
          <img
            src="logo.avif"
            style={{ height: "25px" }}
            alt="Tiebreaker Logo"
          />
        </Panel>
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default function App() {
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  );
}
